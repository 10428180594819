import React, { FC } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../../atoms/ActionButton'
import { PictureProps } from '../../atoms/Picture'
import { HtmlProps } from '../../atoms/Html'

import { BlogCardImagePosition, BlogCardSize } from './styled'
import * as SC from './styled'

export type BlogCardProps = MinimalSeoBlock & {
  className?: string
  subtitle?: string
  title?: string
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
  imageProps?: PictureProps
  mobileImageProps?: PictureProps
  imagePosition?: BlogCardImagePosition
  size?: BlogCardSize
  backgroundColor?: string
  titleColor?: string
}

const BlogCard: FC<BlogCardProps> = (props) => {
  const {
    className,
    htmlTag,
    title,
    subtitle,
    htmlTitleTag,
    htmlProps,
    actionButtonProps,
    imageProps,
    mobileImageProps,
    imagePosition = BlogCardImagePosition.pictureLeft,
    size = BlogCardSize.normal,
    backgroundColor,
    titleColor,
  } = props

  return (
    <SC.BlogCard
      className={cx('Blog', className)}
      as={htmlTag}
      $imagePosition={imagePosition}
      $size={size}
      $backgroundColor={backgroundColor}
    >
      {!mobileImageProps && imageProps && (
        <SC.StyledPicture {...imageProps} $size={size} />
      )}
      {mobileImageProps && imageProps && (
        <>
          <SC.MobileStyledPicture {...mobileImageProps} $size={size} />
          <SC.DesktopStyledPicture {...imageProps} $size={size} />
        </>
      )}
      <SC.Content $size={size}>
        {(title || subtitle) && (
          <SC.Header>
            {title && <SC.Title $color={titleColor}>{title}</SC.Title>}
            {subtitle && (
              <SC.Subtitle as={htmlTitleTag}>{subtitle}</SC.Subtitle>
            )}
          </SC.Header>
        )}
        {htmlProps && <SC.Text $size={size} {...htmlProps} />}
        {actionButtonProps && <SC.Action {...actionButtonProps} />}
      </SC.Content>
    </SC.BlogCard>
  )
}

export default BlogCard
