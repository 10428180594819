import styled, { css } from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'

export enum BlogCardImagePosition {
  pictureLeft = 'pictureLeft',
  pictureRight = 'pictureRight',
}
export enum BlogCardSize {
  small = 'small',
  normal = 'normal',
}
export enum BlogCardVariant {
  Default = 'default',
  Positive = 'positive',
}

export const BlogCard = styled.article<{
  $imagePosition?: BlogCardImagePosition
  $size?: BlogCardSize
  $backgroundColor?: string
}>`
  padding: ${({ $size }) =>
    $size === BlogCardSize.small ? '1rem' : '2rem 1.5rem'};

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 2rem;
  border-radius: 2rem;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.colors.pureWhite};

  @media ${mq(sizes.tablet)} {
    flex-direction: ${({ $imagePosition }) =>
      $imagePosition === BlogCardImagePosition.pictureLeft
        ? 'row'
        : 'row-reverse'};
    padding: ${({ $size }) => ($size === BlogCardSize.small ? '1rem' : '4rem')};
    gap: ${({ $size }) => ($size === BlogCardSize.small ? '3rem' : '5rem')};
    border-radius: 3rem;
  }
`
export const Header = styled.h3`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin: 0;
`
export const Title = styled.div<{ $color?: string }>`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  color: ${({ theme, $color }) => $color ?? theme.colors.cornflowerBlue};
  text-transform: uppercase;
  text-align: left;
`

export const Subtitle = styled.div`
  ${({ theme }) => theme.textStyles.titleH4}
  margin: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.arapawa};
`

export const Content = styled.div<{ $size?: BlogCardSize }>`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  @media ${mq(sizes.tablet)} {
    gap: 2rem;
  }
`

export const Text = styled(Html)<{ $size?: BlogCardSize }>`
  color: ${({ theme }) => theme.colors.arapawa};
  ${({ $size }) =>
    $size === BlogCardSize.small
      ? css`
          ${({ theme }) => theme.textStyles.textSmall}
        `
      : css`
          ${({ theme }) => theme.textStyles.textCaptions}
        `};
`

export const Action = styled(ActionButton)`
  align-self: flex-start;
`

export const StyledPicture = styled(Picture)<{
  $imagePosition?: BlogCardImagePosition
  $size?: BlogCardSize
}>`
  flex: 0 0 auto;
  border-radius: 1rem;
  overflow: hidden;
  padding: 0;
  width: 100%;
  align-self: ${({ $size }) =>
    $size === BlogCardSize.small ? 'stretch' : 'flex-start'};
  aspect-ratio: ${({ $size }) => $size === BlogCardSize.normal && '4/3'};
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    object-fit: cover;
  }
  @media ${mq(sizes.tablet)} {
    max-width: ${({ $size }) =>
      $size === BlogCardSize.small ? '22rem' : '44rem'};
    aspect-ratio: ${({ $size }) => $size === BlogCardSize.normal && '1/1'};
    & img {
      height: 100%;
    }
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`

export const DesktopStyledPicture = styled(StyledPicture)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileStyledPicture = styled(StyledPicture)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
