import React, { FC } from 'react'

import { BlogCardProps } from '../BlogCard'
import TagsCloud, { TagsCloudProps } from '../TagsCloud'
import { VideoBlockProps } from '../VideoBlock'

import * as SC from './styled'
import { BlogCardsBlockSpacing } from './types'

export type BlogCardsBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  cards?: (BlogCardProps | VideoBlockProps)[]
  tagsCloud?: TagsCloudProps
  spacing?: BlogCardsBlockSpacing
}

const BlogCardsBlock: FC<BlogCardsBlockProps> = ({
  className,
  htmlTag,
  title,
  htmlTitleTag,
  cards,
  tagsCloud,
  spacing,
}) => {
  return (
    <SC.BlogCardsBlock className={className} as={htmlTag} $spacing={spacing}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      {tagsCloud && <TagsCloud {...tagsCloud} />}

      {cards && (
        <SC.Cards $spacing={spacing}>
          {cards?.map((card, i) => {
            return 'videoProps' in card ? (
              <SC.Video key={i} {...card} />
            ) : (
              <SC.Card key={i} {...card} $background={card.backgroundColor} />
            )
          })}
        </SC.Cards>
      )}
    </SC.BlogCardsBlock>
  )
}

export default BlogCardsBlock
