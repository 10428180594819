import tracking, { TrackingCardPosition } from 'src/tracking'

import { CulinaryFolder, Maybe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { RecipeCardProps } from '../../components/molecules/RecipeCard'
import { CardContentType } from '../../components/molecules/ContentCard'

export const transformFolderCard = (
  t: any,
  data?: CulinaryFolder | Maybe<CulinaryFolder>,
  blockName?: string
): RecipeCardProps => ({
  type: CardContentType.Folder,
  title: data?.title,
  ...(data?.slug && {
    linkProps: {
      href: Router.getRouteUrl(routes.folder, { slug: data?.slug }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Folder,
          data.title,
          TrackingCardPosition.CONTENT
        ),
    },
  }),
  imageProps: {
    maxWidth: 450,
    width: 384,
    height: 240,
    withHD: true,
    withLazyLoading: true,
    images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
    placeholder: !data?.thumbnail01 && !data?.mainImage,
    alt: data?.title ?? data?.thumbnail01?.alt ?? data?.mainImage?.alt ?? '',
  },
  mobileImageProps: {
    maxWidth: 140,
    width: 140,
    height: 140,
    withHD: true,
    withLazyLoading: true,
    images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
    placeholder: !data?.thumbnail01 && !data?.mainImage,
    alt: data?.title ?? data?.thumbnail01?.alt ?? data?.mainImage?.alt ?? '',
  },
  tags: data
    ? [
        // drop all the tags in order then slice the first 2
        ...(data?.positiveFood
          ? [
              {
                label: t('positive_food'),
                variant: TagVariant.positive,
              },
            ]
          : []),
        { label: t('tag_folder'), variant: TagVariant.folder },
        ...('tagsConsumptionTime' in data && data?.tagsConsumptionTime?.[0]
          ? [
              {
                label: data?.tagsConsumptionTime?.[0]?.name,
                variant: TagVariant.meal,
              },
            ]
          : []),
        ...('tagsTheme' in data && data?.tagsTheme?.[0]
          ? [
              {
                label: data?.tagsTheme?.[0]?.name,
                variant: TagVariant.meal,
              },
            ]
          : []),
      ].slice(0, 2)
    : undefined,
})
