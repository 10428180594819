import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import ListIconHtml from '../../atoms/ListIconHtml'
import Picture from '../../atoms/Picture'
import FormikForm from '../../form/FormikForm'
import { ErrorMessage } from '../../form/fields/FormFieldInput/styled'
import FormSubmitButton from '../../form/FormSubmitButton'
import FormikField from '../../form/FormikField'
import Icon from '../../atoms/Icon'
import Html from '../../atoms/Html'

export enum RegisterBlockPosition {
  col = 'col',
  row = 'row',
}

export const RegisterBlock = styled.section`
  width: 100%;
  max-width: min(
    calc(100vw - 3rem),
    ${({ theme }) => theme.sizes.scoping / 10}rem
  );
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;
  overflow: hidden;

  @media print {
    display: none;
  }
`
export const Header = styled.header`
  position: relative;
  padding: 3.2rem 2.4rem;
  background: ${({ theme }) => theme.colors.arapawa};
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4}
  position: relative;
  z-index: 2;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3}
  }
`
export const Background = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.2;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const DesktopBackground = styled(Background)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBackground = styled(Background)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const Content = styled.div<{ $position: RegisterBlockPosition }>`
  @media ${mq(sizes.tablet)} {
    display: ${({ $position }) =>
      $position === RegisterBlockPosition.row ? 'flex' : 'block'};
    flex-flow: row nowrap;
    & > * {
      flex: 1 1 0;
    }
  }
`
export const SucessContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.snow};
`
export const CheckMark = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.ruby};
  border-radius: 2.4rem;
  padding: 1.4rem;
  width: 6rem;
  height: 6rem;
  color: ${({ theme }) => theme.colors.pureWhite};
  margin-right: auto;
  margin-left: auto;
`
export const Text = styled(Html)`
  text-align: center;
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) => theme.colors.bismark};
`
export const Infos = styled(ListIconHtml)`
  gap: 2rem;
  padding: 3.2rem 1.6rem 3.2rem 4rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  & li {
    ${({ theme }) => theme.textStyles.textMediumStrong}
    color: ${({ theme }) => theme.colors.arapawa};
  }
  & strong {
    ${({ theme }) => theme.textStyles.titleH4}
    display: block;
    color: ${({ theme }) => theme.colors.ruby};
  }
  & li > svg {
    width: 2.8rem;
    height: 2.8rem;
  }
  @media ${mq(sizes.tablet)} {
    padding: 3.2rem 4rem 3.2rem 4rem;
  }
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  padding: 3.2rem 2.4rem;
  background: ${({ theme }) => theme.colors.snow};
  & ${ErrorMessage} {
  }
  @media ${mq(sizes.tablet)} {
    padding: 4rem;
  }
`
export const FormTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH4}
  text-align: center;
  color: ${({ theme }) => theme.colors.arapawa};
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3}
  }
`
export const LegalField = styled(FormikField)`
  & label {
    color: ${({ theme }) => theme.colors.bismark};
    align-items: flex-start;
  }
  & label p {
    ${({ theme }) => theme.textStyles.textXSmall}
  }
  & a {
    color: inherit;
  }
`
export const Submit = styled(FormSubmitButton)``

export const Legal = styled(Html)`
  color: ${(props) => props.theme.colors.arapawa};
  ${({ theme }) => theme.textStyles.textXSmall};
  & > p {
    margin: unset;
  }
  & > p:not(:last-child) {
    padding-block-end: 0.6rem;
  }
  & a {
    color: inherit;
    text-decoration: underline;
  }
`
