import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React from 'react'

import NewsletterPush, {
  NewsletterPushProps,
} from '../../components/molecules/NewsletterPush'
import { selectors } from '../../redux'
import RegisterBlock, {
  RegisterBlockProps,
} from '../../components/molecules/RegisterBlock'
import { RegisterBlockPosition } from '../../components/molecules/RegisterBlock/styled'
import { useNewsletterPush } from '../NewsletterPush/useNewsletterPush'

import { RegisterBlockDefaultProps } from './transform'

export function useRegisterBlock(): React.ReactNode {
  const { t } = useTranslation()
  const isConnected = useSelector(selectors.auth.isConnected)

  const newsletterPushProps: NewsletterPushProps | undefined =
    useNewsletterPush({
      trackingPage: 'sidebar',
    })

  const registerBlockProps: RegisterBlockProps = {
    ...RegisterBlockDefaultProps(t),
    position: RegisterBlockPosition.col,
  }

  return isConnected ? (
    newsletterPushProps && <NewsletterPush {...newsletterPushProps} />
  ) : (
    <RegisterBlock {...registerBlockProps} />
  )
}
