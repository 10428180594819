import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import ActionButton from '../../atoms/ActionButton'
import VideoBlock from '../VideoBlock'
import BlogCard from '../BlogCard'

import { BlogCardsBlockSpacing } from './types'

export const BlogCardsBlock = styled.section<{
  $spacing?: BlogCardsBlockSpacing
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
  gap: ${({ $spacing }) =>
    $spacing === BlogCardsBlockSpacing.PositiveFood ? '4rem' : '2rem'};
  @media ${mq(sizes.tablet)} {
    padding: 0 4rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3};
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  flex-grow: 1;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
  }
`

export const Video = styled(VideoBlock)`
  min-width: 100%;
  margin: 2rem 0;

  & h2 {
    color: ${({ theme }) => theme.colors.arapawa};
  }
`

export const Cards = styled.div<{ $spacing?: BlogCardsBlockSpacing }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $spacing }) =>
    $spacing === BlogCardsBlockSpacing.PositiveFood ? '4rem' : '1.5rem'};
`

export const Card = styled(BlogCard)<{ $background?: string }>`
  background-color: ${({ theme, $background }) =>
    $background ? $background : theme.colors.snow};
`

export const Button = styled(ActionButton)`
  margin-top: 4rem;
`
